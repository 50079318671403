<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="sample-requests"
              :show-labels="false"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="getData"
              @change="filterData"
              @async-search="onAsyncSearch"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <sample-requests-table ref="sample-requests" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import SampleRequestsTable from "@/views/PosAndSamples/SampleRequestsTable";

export default {
  name: "Accounts",
  components: {
    FilteringPanel,
    SampleRequestsTable
  },
  data: function() {
    return {
      filteringPanel: {
        loaded: false,
        selected: {},
        districts: [],
        filters: [
          {
            type: "select",
            title: "Account",
            tooltip: "Account name",
            name: "sample_request_account",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            multiple: true,
            async: true,
            loading: false,
            startsWith: false
          },
          {
            type: "select",
            title: "Requested By",
            tooltip: "Responsible user name",
            name: "requested_by",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            multiple: true
          },
          {
            type: "select",
            title: "Reviewed",
            tooltip: "Review status",
            name: "sample_request_reviewed",
            trackby: "id",
            label: "label",
            options: [
              { id: "1", label: "Yes" },
              { id: "0", label: "No" }
            ],
            selected: {},
            dataType: "string",
            multiple: true
          },
          {
            type: "select",
            title: "Delivered",
            tooltip: "Ship status",
            name: "sample_request_delivered",
            trackby: "id",
            label: "label",
            options: [
              { id: "No", label: "No" },
              { id: "Shipped", label: "Shipped" },
              { id: "Picked up", label: "Picked up" }
            ],
            selected: {},
            dataType: "string",
            multiple: true
          },

          {
            type: "select",
            title: "Country",
            tooltip: "Country name",
            name: "country",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            multiple: true
          },
          {
            type: "select",
            title: "State",
            tooltip: "State name",
            name: "state",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            multiple: true
          },
          {
            type: "select",
            title: "City",
            tooltip: "City name",
            name: "city",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            multiple: true
          },
          {
            type: "select",
            title: "Barrel Samples",
            tooltip: "Barrel Samples",
            name: "sample_request_barrel_sample",
            trackby: "id",
            label: "label",
            options: [
              { id: "No", label: "No" },
              { id: "Yes", label: "Yes" }
            ],
            selected: {},
            dataType: "string",
            multiple: true
          },

          {
            type: "daterange",
            defaultRange: "This year",
            title: "Selection period",
            name: "sample_request_period",
            dataType: "datetime",
            tooltip: "Selection period",
            allowEmpty: true
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onAsyncSearch(payload) {
      if (payload.filterName === "sample_request_account") {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === "sample_request_account"
        );

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      }
    },
    async loadDictionaries() {
      let self = this;

      const districts = async () => {
        let response = await this.$api.post("dictionaries/countries/mapped", {
          ignore_restrictions: false
        });

        self.districts = response;

        self.filteringPanel.districts = self.districts;

        self.filteringPanel.filters.find(
          f => f.name === "country"
        ).options = self.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );
      };

      const users = async () => {
        let response = await this.$api.get("users");

        self.filteringPanel.filters.find(
          f => f.name === "requested_by"
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      await Promise.all([districts(), users()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },

    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      //if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    filterData: function(e) {
      this.filteringPanel.selected = e;
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        accounts: f.sample_request_account
          ? f.sample_request_account.map(i => i.label)
          : [],
        requested_by: f.requested_by ? f.requested_by.map(i => i.id) : [],
        reviewed: f.sample_request_reviewed
          ? f.sample_request_reviewed.map(i => i.id)
          : [],
        delivered: f.sample_request_delivered
          ? f.sample_request_delivered.map(i => i.label)
          : [],
        countries: f.country ? f.country.map(i => i.label) : [],
        states: f.state ? f.state.map(i => i.label) : [],
        cities: f.city ? f.city.map(i => i.label) : [],
        barrel_sample: f.sample_request_barrel_sample
          ? f.sample_request_barrel_sample.map(i => i.id)
          : [],
        sample_request_period: f.sample_request_period
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["sample-requests"].getData(payload);
    }
  },
  watch: {
    "filteringPanel.selected.country": function(newVal) {
      if (!newVal) return;

      this.filteringPanel.filters.find(
        f => f.name === "state"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        "state",
        "state",
        "id",
        "label"
      );
    },
    "filteringPanel.selected.state": function(newVal) {
      if (!newVal) return;
      this.filteringPanel.filters.find(
        f => f.name === "city"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s => newVal.map(l => l.label).includes(s.state)),
        "city",
        "city",
        "id",
        "label"
      );
    }
  }
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
